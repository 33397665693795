
/* eslint-disable */
import { defineComponent, onMounted, reactive, watch, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
import affTicket from "@/views/still/deploy/affTicket.vue";
import { useI18n } from "vue-i18n";

import * as moment from "moment";

export default defineComponent({
  name: "create-edit-site",
  components: {
    Datatable,
    affTicket,
    SearchAndFilter
  },
  props: {
    mCode: String,
  },
  setup() {
    //moment.default.locale("fr");

    const store = useStore();
    let mSoc_seq = 0;

    const router = useRouter();
    const { t } = useI18n()

    const state = reactive({
      myListStock: [] as any,
      myListStockAll: [] as any,
      mySiteList: [],
      myContactList: [],
      mySocieteList : [] as any,
      initialMyList: [] as any,
      initialMySiteList: [] as any,
      renderSiteSAF: 0,
      loaderEnabled : true,
      loaderCreation : false,
      loadingDatatable: 0,
      loaderTableList : false,
      search: "",
      refMask: 0,
      idTicket: 0,    
      isStill: false,  
      titleModal : t("Création d\'un nouveau ticket"),
      subTitle : t("Création du ticket"),
      etapes : [] as any,
      prc_knum_client: 0,

      listContacts: [] as any,
      activeLieu : 'factu',

      formData : {
        tic_launch:0,
        tic_knum_societe : 0,
        tic_c_label:'',
        tic_c_serv : [
          /*
          {code:'validation', denom : '', name: 'validation', subtitle:'', type_exped : 0, knum_societe : mSoc_seq, knum_contact : 0, xlieu : false, knum_lieu : 0, xCheckUser : true, svg: "maps/map009.svg", xMateriel : true, knum_lieu_is_princ: true, visible: false, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          {code:'staging', denom : 'le ', name: 'staging',  subtitle:'Transmettez-nous votre configuration matériel',knum_societe : mSoc_seq, knum_contact : 0,  xlieu : false, knum_lieu : 0, xCheckUser : false, svg: "technology/teh001.svg", xMateriel : true, knum_lieu_is_princ: true, visible: true, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          {code:'exped', denom : 'l\'', name: 'expédition', subtitle:'Procédez à une sortie de stock et lancer l\'expédtion de vos produits', type_exped : 0, knum_societe : mSoc_seq, knum_contact : 0, xlieu : true, knum_lieu : 0, xCheckUser : true, svg: "maps/map009.svg", xMateriel : true, knum_lieu_is_princ: true, visible: true, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          {code:'deploy', denom : 'le ', name: 'déploiement',  subtitle:'Faites une demande de prestations d\'ingénierie qualifié',knum_societe : mSoc_seq, knum_contact : 0,  xlieu : true, knum_lieu : 0, xCheckUser : false, svg: "technology/teh005.svg", xMateriel : false, knum_lieu_is_princ: true, visible: true, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          {code:'custom', denom : '', name: '', subtitle: 'Service spécifique', xlieu : true,knum_societe : mSoc_seq, knum_contact : 0, knum_lieu : 0, xCheckUser : false, svg: "abstract/abs026.svg", xMateriel : true, knum_lieu_is_princ: true, visible: true, desc_globale : '', date1:'', date2:'', date3:'', fichiers: [] as any},
          {code:'factu', denom : 'la ', name: 'facturation', subtitle: '', xlieu : true,knum_societe : mSoc_seq, knum_contact : 0, knum_lieu : 0, xCheckUser : true, svg: "", xMateriel : false, knum_lieu_is_princ: true, visible: false, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          */
        ] as any,
        tic_list_matos : [] as any,
        tic_staging : [] as any
      },
      totStep : 1,
      step : 1,
      stepCode : 'info',
      countSocLoaded: 0,
      countSocChanged: 0,
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const tableHeader = ref([
      { name: "Equipement", key: "loc_reference",  sortable: true,  },
      { name: "Quantité Disponible", key: "qte", sortable: false, },
      { name: "Saisir quantité", key: "loc_id", sortable: false, },
    ]);

    const tableHeaderStaging = ref([
      { name: "Equipement", key: "loc_id",  sortable: false,  },
      { name: "Fichiers", key: "fichiers", sortable: false, },
      { name: "Commentaires", key: "commentaires", sortable: false, },
    ]);

    const tableHeaderSite = ref([
      { name: "", key: "status", sortable: false, },
      { name: "Libellé", key: "adr_seq", sortable: false, },
      { name: "Adresse", key: "adr_ligne_1", sortable: false, },
      { name: "Contact", key: "contacts", sortable: false, },
    ]);

    const tableFilters = ref([
      {
        name: "Commande",
        key: "cmdFilter",
        label: "cmdFilter",
      },
    ]);

    const tableFiltersAdr = ref([
      {
        name: "Type de site",
        key: "adr_c_site_type",
        label: "adr_c_site_type",
      },
    ]);

    const selectRows = (item) => {
      state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_lieu = item.adr_seq
    }

    const refreshSites = () => {
      state.loadingDatatable += 1;
    }
    
    watch(state.formData, () => {

      if (!state.loaderTableList) return false;

      state.etapes = [];
      state.etapes.push({code:'info', title: 'informations', desc: 'Généralités sur le ticket', visible : true});

      let checkFactuValid = true
      let checkUserNb = state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.code != 'validation' && element.code != 'factu').length
      state.formData.tic_c_serv.forEach(el => {
        if (checkUserNb == 1 && el.cat_x_uncheck_others == true && el.xCheckUser == true) {
          checkFactuValid = false
        }
      });
      state.formData.tic_c_serv.find(element => element.code == 'validation').xCheckUser = checkFactuValid
      state.formData.tic_c_serv.find(element => element.code == 'factu').xCheckUser = checkFactuValid
           
      if (state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.xMateriel == true )) state.etapes.push({code:'lstMat', title: 'Liste du matériel', desc: 'Quel matériel est concerné ?', visible : true});
      if (state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.code == "staging" )) state.etapes.push({code:'staging', title: 'Staging', desc: 'Configuration du matériel', visible : true});
      if (state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.code == "exped" )) state.etapes.push({code:'exped', title: 'Expédition', desc: 'Organisez la sortie de matériels', visible : true});
      if (state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.code == "deploy" )) state.etapes.push({code:'deploy', title: 'Déploiement', desc: 'Prestations d\'ingénierie', visible : true});
      
      state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.code.indexOf("custom") >= 0 ).forEach(myElement => {
        state.etapes.push({code:myElement.code, title: myElement ? myElement.name : '', desc: 'Service spécifique', visible : true});
      });

      state.etapes.push({code:'lieu', title: 'Adresse et contact', desc: 'Sélection de site(s)', visible : true});
      state.etapes.push({code:'last', title: 'Synthèse du ticket', desc: 'Et création de votre ticket.', visible : true});

      if (state.stepCode!='lieu') {
        const activeLieu = state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.xlieu == true);
        state.activeLieu = (activeLieu && state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == 'exped')].type_exped !== 3) ? activeLieu.code : 'factu';

        if (state.mySocieteList.length > 0) state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_societe = state.mySocieteList[0].soc_seq;
        if (state.countSocLoaded == 0 || (state.mySocieteList.length > 1 && state.countSocChanged == 1)){
          changeSocieteSite()
          state.countSocLoaded = 1
          state.countSocChanged = 0
        }
      }
      
      //if (activeLieu) activeLieu.knum_lieu_is_princ = true;

    });

    onMounted(async () => {
      if (router.currentRoute.value.params.prc_knum_client) {
        state.prc_knum_client = router.currentRoute.value.params.prc_knum_client as any
      }else{
        const prc_knum_client = await mAxiosApi.prototype.getAxios("/clearIsPresta")
      }
      mSoc_seq = store.getters.currentUser.con_knum_societe;
      if (!mSoc_seq) {
        const res = await mAxiosApi.prototype.getAxios("/me");
        mSoc_seq = res.con_knum_societe;
      }
      addSite();
    });

    const previousStep = () => {
      if (state.etapes[state.step-1].code == "lstMat") {
        state.myListStock = state.initialMyList
      }
      if (state.etapes[state.step-1].code == "last") {
        state.mySiteList = state.initialMySiteList
      }
      state.step -= 1;
      state.stepCode = state.etapes[state.step-1].code;
    }
    const nextStep = async () => {
      if (state.etapes[state.step-1].code == "info") {
        const item = state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.name == '')
        const servWithFactu = state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.cat_x_no_factu == false && element.code != 'factu' && element.code != 'validation')
        const servWithoutFactu = state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.cat_x_no_factu == true && element.code != 'factu' && element.code != 'validation')
        const servWithAutovalid = state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.cat_x_autovalid == true && element.code != 'factu' && element.code != 'validation')
        const servWithoutAutovalid = state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.cat_x_autovalid == false && element.code != 'factu' && element.code != 'validation')
        const checkedServ = state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.code != 'factu' && element.code != 'validation')
        console.log(servWithFactu)
        if (item !== undefined) {
          ElMessage({showClose: true,  message: t("Attention, le champ") + ' ' +  item.subtitle + ' ' + t("ne peut être vide."),  type: 'error', })
          return false;
        }
        if (servWithFactu.length > 0 && servWithoutFactu.length > 0) {
          ElMessage({showClose: true,  message: t("Attention : les services " + servWithFactu[0].name + " et " + servWithoutFactu[0].name + " ne sont pas compatibles"),  type: 'error', })
          return false;
        }
        if (servWithAutovalid.length > 0 && servWithoutAutovalid.length > 0) {
          ElMessage({showClose: true,  message: t("Attention : les services " + servWithFactu[0].name + " et " + servWithoutFactu[0].name + " ne sont pas compatibles"),  type: 'error', })
          return false;
        }
        if (checkedServ.length == 0) {
          ElMessage({showClose: true,  message: t("Veuillez sélectionner au moins un service"),  type: 'error', })
          return false;
        }
        const first = state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.xlieu == true);
      }

      if (state.etapes[state.step-1].code == "exped") {
        const type_exped = state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == 'exped')].type_exped;
        if (type_exped == 1 && !state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == 'exped')].date1) {
          ElMessage({showClose: true,  message: t("Attention, vous devez saisir une date."),  type: 'error', })
          return false;
        }
        state.mySiteList = state.initialMySiteList
        if (type_exped == 3) {
          state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == 'exped')].knum_lieu = 0
          state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == 'factu')].knum_lieu_is_princ = false
        }else{
          state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == 'factu')].knum_lieu_is_princ = true
        }
      }

      if (state.etapes[state.step].code == "last") {

        const itemsLieu = state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.xlieu == true);

        // console.log(itemsLieu);

        if (state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == 'exped')].type_exped === 3) {
          if (itemsLieu[1].knum_lieu == 0) {
            ElMessage({showClose: true,  message: t("Attention, vous devez sélectionner une adresse de facturation."),  type: 'error', })
            return false;
          }
        }else{
          if (itemsLieu[0].knum_lieu == 0) {
            ElMessage({showClose: true,  message: t("Attention, vous devez sélectionner une adresse principale."),  type: 'error', })
            return false;
          }
        }

        let count = 0;
        let socDefaut = 0;
        let LieuDefaut = 0;
        let ContactDefaut = 0;

        itemsLieu.forEach((val) => {
          if (count != 0) {
            if (val.knum_lieu_is_princ) {
              val.knum_lieu = LieuDefaut;
              val.knum_societe = socDefaut;
              val.knum_contact = ContactDefaut;
            }            
          } else {
              LieuDefaut = val.knum_lieu;
              socDefaut = val.knum_societe;
              ContactDefaut = val.knum_contact;
          }
          count += 1;

        });       
      }


      
      if (state.etapes[state.step-1].code == "lstMat") {
        
        const itemInitial = state.initialMyList.filter(element => element.qte_exped >0)
        if (itemInitial.length>0) {
          state.myListStock = state.initialMyList
        }
        let item = state.myListStock.filter(element => element.qte_exped >0)

        let error = "";
        item.forEach((val) => {  
          if (parseFloat(val.qte_exped) % 1 !== 0) {
            error = t("Attention, les quantités doivent être des nombres entiers");
            return false;
          }
        });
        if (error.length > 0) {
          ElMessage({showClose: true,  message: error,  type: 'error', })
          return false;
        }
        if (item.length == 0) {
          ElMessage({showClose: true,  message: t("Attention, aucune quantité n\'a été saisie."),  type: 'error', })
          return false;
        }

        // Build liste staging
        item.forEach((val) => {
          let count = 0;
          for (let i = 0; i < val.qte_exped; i++) {
            
            const exist = state.formData.tic_staging.find(element => element.loc_id == i && element.loc_reference == val.loc_reference && val.numcmd == element.numcmd);
            if (!exist) {
              state.formData.tic_staging.push({ 
                  loc_id: i,
                  loc_reference: val.loc_reference,
                  loc_toc_seq : val.toc_code,
                  loc_designation: val.loc_designation,
                  numcmd : val.numcmd,
                  old_reference :  val.old_reference,
                  date_reappro: val.date_reappro,
                  fichiers: [] as any,
                  commentaires : '',
              });
            }else {
              // console.log("Wtf ?!", val.loc_reference);
            }
            count += 1;
          }

          const OtherItems = state.formData.tic_staging.filter(element => element.loc_id >= count && element.loc_reference == val.loc_reference && val.numcmd == element.numcmd);
          OtherItems.forEach(f => state.formData.tic_staging.splice(state.formData.tic_staging.findIndex(e => e.loc_id === f.loc_id),1));

        });



        // Delete orphelin
        const orph = state.myListStock.filter(element => element.qte_exped == 0)
        orph.forEach((val) => {
          const OtherItems = state.formData.tic_staging.filter(element => element.loc_reference == val.loc_reference && element.numcmd == val.numcmd);
          OtherItems.forEach(f => state.formData.tic_staging.splice(state.formData.tic_staging.findIndex(e => e.loc_id === f.loc_id),1));
        });

        state.formData.tic_staging.sort(function (a, b) {
            let x = a.loc_reference.toUpperCase(),
                y = b.loc_reference.toUpperCase();
            return x == y ? 0 : x > y ? 1 : -1;
        });

        state.formData.tic_list_matos = state.myListStock.filter(element => element.qte_exped >0);
        // console.log("state.formData.tic_staging", state.formData.tic_staging);

      }

      state.stepCode = state.etapes[state.step].code;
      state.step += 1;
    }

    const openUpload = (id) => {
      const file1: any = document.querySelector('input[id='+id+']');
      file1.click();
    }
    const handleChangeUpload= (loc_reference, loc_id) => {
      const identif = loc_reference+'_'+loc_id;
      const file1: any = document.querySelector('input[id='+identif+']');
      const file = file1.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(e) {

          if (file.size/1024/1024 > 10) {
            ElMessage({showClose: true,  message: t("Attention, la taille du fichier ne peut dépasser 10 MO !"),  type: 'error', })
            return false 
          }
          
          if(state.stepCode.indexOf('custom') >=0 || state.stepCode=='deploy') {
            if(e.target) state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].fichiers.push({ name: file.name, data: e.target.result });
          }else {
            if(e.target) state.formData.tic_staging[state.formData.tic_staging.findIndex(element => element.loc_reference == loc_reference && element.loc_id == loc_id)].fichiers.push({ name: file.name, data: e.target.result });
          }
        };
      }
    }
    const deleteFile = (list, index) => {
      list.splice(index, 1);
    }

    const resetForm = () => {
      // state.formData = { };
    };

    async function addSite() {
      state.loaderTableList = false;
      state.idTicket = 0;
      state.titleModal = t("Création d\'un nouveau ticket");
      state.subTitle = t("Création du ticket");
      state.formData.tic_launch = 1;

      let myListService = await mAxiosApi.prototype.getAxios("/getCatserv");
      
      let uncheckOther = -1;
      myListService.results.forEach((el) => {
        el.cat_json = JSON.parse(el.cat_json);
        el.cat_json.knum_societe = mSoc_seq;
        el.cat_json.code = el.cat_w_code;
        el.cat_json.denom = el.cat_w_denom;
        el.cat_json.name = el.cat_w_name;
        el.cat_json.subtitle = el.cat_w_subtitle;
        el.cat_json.xlieu = parseInt(el.cat_w_xLieu) ? true : false;
        el.cat_json.xMateriel = parseInt(el.cat_w_xMateriel) ? true : false;
        el.cat_json.svg = el.cat_x_svg;
        el.cat_json.cat_x_uncheck_others = parseInt(el.cat_x_uncheck_others) ? true : false;

        if(el.cat_json.cat_x_uncheck_others) uncheckOther = state.formData.tic_c_serv.length;

        el.cat_json.visible = parseInt(el.cat_w_visible) ? true : false;
        el.cat_json.cat_x_no_factu = parseInt(el.cat_x_no_factu) ? true : false;
        el.cat_json.cat_custom_x_dates_inter = parseInt(el.cat_custom_x_dates_inter) ? true : false;
        el.cat_json.cat_custom_label_desc = el.cat_custom_label_desc;
        el.cat_json.cat_custom_label_placeholder = el.cat_custom_label_placeholder;
        
        el.cat_json.cat_json_custom = isJSON(el.cat_json_custom) ? JSON.parse(el.cat_json_custom) : "";

        el.cat_json.cat_json_custom_values = {};

        state.formData.tic_c_serv.push(el.cat_json);
      })

      let myListStock = await mAxiosApi.prototype.getAxios("/getstock");
      let getstockProduct = await mAxiosApi.prototype.getAxios("/getstockProduct");
      let isStill = await mAxiosApi.prototype.getAxios("/getIsStill");
      state.isStill = isStill

      if (uncheckOther >= 0) {
        state.formData.tic_c_serv.forEach((el,index) => {
          if(index != uncheckOther && el.code != 'validation' && el.code != 'factu') el.xCheckUser = false;        
        });
      }


      // console.log("getstockProduct",[...getstockProduct.records]);
      let myListServiceFinal = [] as any;
      state.refMask = 0;

      myListStock.records.forEach((el) => {
        el.qte_exped = 0;
        // el.qte_max = parseInt(el.qte) // + parseInt(el.reappro)
        // el.qte = parseInt(el.qte);      
        el.toc_code = JSON.parse(el.toc_code);
        el.toc_code_all = JSON.parse(el.toc_code_all);

        if (el.liv_ref_secondaire) {
          el.loc_id = el.loc_reference + '-' + el.liv_ref_secondaire;
          el.old_reference = el.loc_reference;
          el.loc_reference = el.liv_ref_secondaire;
          
        }
        

        if(el.toc_code_all.filter(el => el.toc_x_invisible_stock== 1).length > 0 ) {
          el.visible = 0;
          state.refMask++;
        } else {
          el.visible = 1;
        }
        const oldEl = {...el};
        const arrayToc = [...el.toc_code];
        const arrayTocAll = [...el.toc_code_all];
       
        arrayToc.forEach((el2) => {
          el.toc_code = [el2];
          el.toc_code_all = arrayTocAll.filter(al => al.refCmd == el2.numcmd );

          if (!el.liv_ref_secondaire) {
            el.qte_max = getstockProduct.records.filter(aa => aa.toc_code == el2.numcmd && aa.loc_reference == el.loc_reference && !aa.liv_ref_secondaire)[0].en_stock;
            el.total_ticket = getstockProduct.records.filter(aa => aa.toc_code == el2.numcmd && aa.loc_reference == el.loc_reference && !aa.liv_ref_secondaire)[0].total_ticket;
          } else {
            el.qte_max = getstockProduct.records.filter(aa => aa.toc_code == el2.numcmd && aa.loc_reference == el.old_reference && aa.liv_ref_secondaire == el.liv_ref_secondaire)[0].en_stock;
            el.total_ticket = getstockProduct.records.filter(aa => aa.toc_code == el2.numcmd && aa.loc_reference == el.old_reference && aa.liv_ref_secondaire == el.liv_ref_secondaire)[0].total_ticket;
          }

          el.qte = parseInt(el.qte_max);  
          el.numcmd = el2.numcmd;
          el.cmdFilter = el.toc_code[0].code
          if (el.qte>0) {
            myListServiceFinal.push({...el});
          }
        })        
      })

      // console.log("myListServiceFinal",myListServiceFinal);

      myListServiceFinal = myListServiceFinal.sort((a, b) => (a.loc_reference > b.loc_reference) ? 1 : -1)
      state.initialMyList = myListServiceFinal;

      state.myListStock = myListServiceFinal.filter(el => el.qte >  0 );
      state.myListStockAll = myListServiceFinal.filter(el => el.qte >  0 );
      state.myListStock = state.myListStock.filter(el => el.visible == 1 );

      
      let mySocieteList = await mAxiosApi.prototype.getAxios("/getAllSocietes");
      state.mySocieteList = mySocieteList.results;

      let mySiteList = await mAxiosApi.prototype.getAxios("/getSites");
      state.mySiteList = mySiteList.results;
      state.initialMySiteList = mySiteList.results;

      let myListContact
      if (state.prc_knum_client>0) {
        myListContact = await mAxiosApi.prototype.getAxios("/getContacts/" + state.prc_knum_client);
      }else{
        myListContact = await mAxiosApi.prototype.getAxios("/getContacts");
      }
      state.listContacts = myListContact.results;
      state.formData.tic_knum_societe = state.mySocieteList[0].soc_seq;
      state.loaderEnabled = false;
      state.loaderTableList = true;

    }


    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    const recalculStock = (item) => {
      return false;
      /*
      let qte = 0;
      let reappro = 0;
      const toc_code = item.toc_code.filter(element => element.select == true);

      toc_code.forEach((val) => {
        let a_soustraire = 0;
        const toc_code_all = item.toc_code_all.filter(element => element.refCmd == val.numcmd);
        toc_code_all.forEach((val2) => {
            if(a_soustraire == 0 && val2.ticket_cmd > 0) a_soustraire = val2.ticket_cmd;
            qte = qte + val2.qte;
            reappro = reappro + val2.reappro;
        });
        qte = qte - a_soustraire;
      });

      state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].qte = qte;
      state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].reappro = reappro;
      state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].qte_max = qte; // + reappro;

      handleChangeQte(state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].qte_exped, state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].loc_id)
      */
      

    }

    const handleClick = () => {
      changeSocieteSite();
    }

    const testFirstLieu = () => {
      const first = state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.xlieu == true);
      if (first) return first.code;
      return 'factu';
    }

    const handleChangeSoc = () => {
      state.countSocChanged = 1
      changeSocieteSite()
    }

    const changeSocieteSite = async () => {

      state.loaderTableList = false;
      // state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_lieu = 0;
      let soc_seq = state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_societe;
      if (state.prc_knum_client) {
        soc_seq = state.prc_knum_client
      }
      state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_societe = soc_seq;
      
      let mySiteList = await mAxiosApi.prototype.getAxios("/getSites/" + soc_seq);
      state.mySiteList = mySiteList.results;
      state.initialMySiteList = mySiteList.results;
      state.renderSiteSAF = state.renderSiteSAF + 1;

      let myListContact = await mAxiosApi.prototype.getAxios("/getContacts/" + soc_seq);
      state.listContacts = myListContact.results;

      state.loaderTableList = true;
      
    }

    const createTicket = async() => {

      state.loaderCreation = true;

      const form = {
        tic_c_label : state.formData.tic_c_label,
        tic_services : [] as any,
        tic_matos : [] as any,
      }
      const itemsServices = state.formData.tic_c_serv.filter(element => element.xCheckUser == true);
      itemsServices.forEach((val) => {

        form.tic_services.push({
          tis_code : val.code,
          tis_type_exped : val.type_exped ? val.type_exped : 0,
          tis_knum_societe : val.knum_societe,
          tis_knum_contact : val.knum_contact,
          tis_knum_lieu : val.knum_lieu,
          tis_knum_lieu_is_princ : val.knum_lieu_is_princ,
          tis_desc_globale : val.desc_globale,
          tis_date1 : val.date1 ? moment.default(val.date1).format('YYYY-MM-DD') : null,
          tis_date2 : val.date2 ? moment.default(val.date2).format('YYYY-MM-DD') : null,
          tis_date3 : val.date3 ? moment.default(val.date3).format('YYYY-MM-DD') : null,
          tis_custom : val.cat_json_custom_values,
        })
      });

      

      state.formData.tic_staging.forEach((val) => {

        let numCmd = '';
        val.loc_toc_seq.filter(el => el.select == true).forEach((val2) => {
          numCmd += val2.toc_seq + ',';
        });
        numCmd = numCmd.slice(0, -1);

        

        form.tic_matos.push({
          tim_knum_commande : numCmd,
          tim_reference : val.loc_reference,
          old_reference : val.old_reference,
          tim_designation : val.loc_designation,
          tim_commentaire : val.commentaires,
        })
        
      });

      mAxiosApi
        .post("/addTicket", form)
        .then( async(data : any) => {
            
            const allIds = data.data.results;
            const idTicket = allIds.find(el => el.code == 'ticket').seq;

            const itemsServices = state.formData.tic_c_serv.filter(element => element.xCheckUser == true);
            itemsServices.forEach((val) => {
              if (val.fichiers.length > 0) {
                const idService = allIds.find(el => el.code == val.code).seq;

                val.fichiers.forEach((file) => {
                  const formFile = {
                    tif_knum_ticket : idTicket,
                    tif_base64 : file.data,
                    tif_title : file.name,
                    tif_knum_ticket_matos : null,
                    tif_knum_ticket_services : idService
                  }
                  mAxiosApi.post("/addTicket/file", formFile);
                });
              }
            });

            state.formData.tic_staging.forEach((val) => {
              let count = 0;
              const idsMatos = allIds.filter(el => el.code == 'matos');
              // console.log("Matos", idsMatos);
              //console.log("file",val);
              //console.log("Matos", idsMatos);

              if (val.fichiers.length > 0) {
                val.fichiers.forEach((file) => {
                  


                  const formFile = {
                    tif_knum_ticket : idTicket,
                    tif_base64 : file.data,
                    tif_title : file.name,
                    tif_knum_ticket_matos : idsMatos[val.loc_id].seq,
                    tif_knum_ticket_services : null
                  }
                  mAxiosApi.post("/addTicket/file", formFile);
                });
              }
              count +=1;
            });

            Swal.fire({
                text: t("Ticket créé avec succés !"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("Retour à la liste des tickets"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(() => {
                if (state.prc_knum_client) {
                  retourPresta();
                }else{
                  retourTicket();
                }
            });

        })
        
    }

    const handleChangeQte = (value: number, data, toc) => {
      const found = state.myListStock.find(element => element.loc_id == data && element.numcmd == toc );
      const foundInitial = state.initialMyList.find(element => element.loc_id == data && element.numcmd == toc );
      // console.log(data, toc);
      // console.log(state.myListStock);
      found.qte_exped = value;
      foundInitial.qte_exped = value;
      if (found.qte_exped > found.qte_max ) found.qte_exped = found.qte_max;
      if (foundInitial.qte_exped > foundInitial.qte_max ) foundInitial.qte_exped = foundInitial.qte_max;
    }

    const capitalize = (word) => {
      if (word) return word[0].toUpperCase() + word.slice(1).toLowerCase();
      return "";
    }

    const upperCase = (word) => {
      if (word) return word.toUpperCase();
      return "";
    }

    const retourTicket = () => {
      router.push({  name: "ticket" });
    }
    const refreshlmatos = () => {
      state.myListStock = state.myListStockAll
      // console.log(state.myListStockAll);
    }

    const remplir = () => {
      state.myListStock.forEach(element => {
        element.qte_exped = element.qte
      });
    }

    const vider = () => {
      state.myListStock.forEach(element => {
        element.qte_exped = 0
      });
    }

    const retourPresta = () => {
      router.push({  name: "prestaticket" });
    }

    
    return {
      previousStep,
      nextStep,
      state,
      resetForm,
      tableHeader,
      tableHeaderSite,
      formatDate,
      handleChangeQte,
      selectRows,
      refreshSites,
      capitalize,
      upperCase,
      testFirstLieu,
      changeSocieteSite,
      handleClick,
      tableHeaderStaging,
      handleChangeUpload,
      openUpload,
      deleteFile,
      recalculStock,
      createTicket,
      retourTicket,
      retourPresta,
      refreshlmatos,
      tableFilters,
      remplir,
      vider,
      tableFiltersAdr,
      handleChangeSoc
    };

  },
});
